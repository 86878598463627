import {PickedFile} from '@capawesome/capacitor-file-picker';
import {GenericVideoHandlingBackend} from './GenericVideoHandlingBackend';
import {FileOnDisk, FileWriter} from '../files';
import {createVideoThumbnailUsingCapacitorPlugin} from "./helpers";
import {MediaFile} from '@awesome-cordova-plugins/media-capture';
import {Capacitor} from '@capacitor/core';

export class AndroidVideoHandlingBackend extends GenericVideoHandlingBackend {
    async canLoadVideosFromCamera(): Promise<boolean> {
        return true;
    }

    async canLoadVideosFromGallery(): Promise<boolean> {
        return true;
    }

    protected getVideoLength(file: PickedFile): Promise<number> {
        return Promise.resolve(file.duration!);
    }

    // Android has issues with the resulting URLs, therefor the data is read
    // and copied to memory instead of handled as path. This is not ideal memory
    // wise but the only way to get it working on Android.
    // See: https://github.com/capawesome-team/capacitor-plugins/issues/158
    protected async toFileOnDisk(writer: FileWriter, file: PickedFile|MediaFile): Promise<FileOnDisk> {
        const path = 'fullPath' in file ? file.fullPath! : file.path!;
        const response = await fetch(Capacitor.convertFileSrc(path));
        const blob = await response.blob();

        return writer.writeToDisk(file.name, blob);
    }

    protected async createVideoThumbnail(writer: FileWriter, path: string): Promise<FileOnDisk> {
        const thumbnail = await createVideoThumbnailUsingCapacitorPlugin(path);

        return this.fileHandling.getFile(thumbnail)
    }
}