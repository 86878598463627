import React from 'react';
import {
    IonButton,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import {arrowBackOutline} from 'ionicons/icons';
import {environment} from '../environment';
import logo from '../assets/logo_bright.svg';
import {useHistory} from 'react-router';
import {layoutData} from '../data/layout';

interface PageWrapperProps {
    title: string,
    children: React.ReactNode,
    showBackButton?: boolean,
    onBack?: () => void,
    additionalHeaderContent?: React.ReactNode,
    footer?: React.ReactNode,
}

export const PageWrapper: React.FunctionComponent<PageWrapperProps> = (props) => {
    const [ pageWidth, setPageWidth ] = React.useState(window.innerWidth);
    const [ lastClick, setLastClick ] = React.useState<number>(-1);
    const history = useHistory();

    // @ts-expect-error Helper for testing
    window.__navigate = (path: string) => history.push(path);

    React.useEffect(() => {
        const handleResize = () => setPageWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const onBack = () => {
        if (props.onBack) {
            props.onBack();
        } else {
            history.goBack();
        }
    };

    const onTitleClick = () => {
        const now = Date.now();
        if (lastClick === -1 || lastClick < now - 500) {
            setLastClick(now);
            return;
        }

        history.push('/home');
    }

    return <IonPage>
        <IonHeader>
            <IonToolbar color="primary">
                <IonGrid fixed={true} style={{display: 'flex'}}>
                    {props.showBackButton &&
                        <IonButton id="page-back-button" shape="round" color="light" fill="outline" onClick={onBack}>
                            <IonIcon icon={arrowBackOutline}/>
                        </IonButton>}
                    {layoutData.showLogoInHeader && pageWidth > 768 && <IonButton fill="clear" href={environment.powderguideWebsiteUrl} style={{
                        fontWeight: 'bold',
                        height: '3rem',
                        fontSize: '2rem',
                        color: 'white',
                    }}>
                        {environment.isProduction ? <IonImg src={logo} style={{height: '100%'}}/> : '⚠ DEV'}
                    </IonButton>}
                    <IonTitle id="page-title" onClick={onTitleClick}>{props.title}</IonTitle>
                </IonGrid>
            </IonToolbar>
            {props.additionalHeaderContent}
        </IonHeader>
        <IonContent>
            <IonGrid fixed={true}>
                {props.children}
            </IonGrid>
        </IonContent>
        {props.footer &&
            <IonFooter>
                <IonToolbar color="primary">
                    <IonGrid fixed={true}>
                        {props.footer}
                    </IonGrid>
                </IonToolbar>
            </IonFooter>
        }
    </IonPage>;
};