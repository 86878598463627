import {useInProgressMiniConditionsReport} from "../../../data/ConditionsReportMiniEditing";
import {useHistory, useParams} from "react-router";
import {ID_NEW} from "../../../data/ConditionsReportProEditing";
import React from "react";
import {conditionsReportRepository} from "../../../data/repository/ConditionsReport";
import {PageWrapper} from "../../../components/PageWrapper";
import {useI18n} from "../../../i18n/i18n";
import {useLoginState} from "../../../data/Login";
import {Redirect} from "react-router-dom";
import {IonButton, IonLoading} from "@ionic/react";
import {ConditionsReportMiniForm, ConditionsReportMiniFormData} from "./Form";
import {ValidationWarnings} from "../../../components/ValidationWarning";
import {fileWriterFactory} from "../../../data/files/writer";
import {FileWriterType} from "../../../data/files/FileWriterFactory";
import {ErrorBox} from "../../../components/ErrorBox";

export const ConditionsReportMiniEditingPage: React.FunctionComponent = () => {
  const { id } = useParams() as { id: string };
  const {label} = useI18n();
  const {user} = useLoginState();
  const history = useHistory();
  const fileWriter = fileWriterFactory.createFileWriter(FileWriterType.MiniConditionsReport, id);
  const [ error, setError ] = React.useState<string | null>(null);

  const {
    initialize,
    goToPreview,
    formData,
    updateFormData,
    validation,
    showValidation,
  } = useInProgressMiniConditionsReport();

  React.useEffect(() => {
    if (id === ID_NEW) {
      initialize(null);
      return;
    }

    conditionsReportRepository.load(id)
      .then(cr => {
        if (cr === null) {
          setError(label('editing.error.not_found'));
        } else {
          initialize(cr);
        }
      });
  }, [ id ]);

  if (user === null) {
    return <Redirect to="/home"/>;
  }

  const content: React.ReactNode[] = [ ];
  if (error) {
    content.push(<ErrorBox key={`page-${id}`} error={error} />)
  } else {
    if (showValidation[id]) {
      content.push(<ValidationWarnings key={`validation-${id}`} errors={validation[id] ?? {}} />);
    }
    if (formData[id]) {
      content.push(<ConditionsReportMiniForm
        key={`form-${id}`}
        fileWriter={fileWriter}
        formData={formData[id]}
        updateFormData={(data: Partial<ConditionsReportMiniFormData>) => updateFormData(id, data)}
        validation={showValidation[id] ? validation[id] : { } }
      />);
    } else {
      content.push(<IonLoading key={`loading-${id}`} />);
    }
  }

  return <PageWrapper
    title={label('editing.mr')}
    showBackButton={true}
    onBack={() => history.push('/home')}
    footer={
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <div />
        <IonButton id="go-to-preview" onClick={() => goToPreview(id)}>{label('editing.action.send')}</IonButton>
      </div>
    }
  >
    {content}
  </PageWrapper>
}
