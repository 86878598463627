import React from 'react';
import {IonButton, IonItem, IonLabel, IonList} from '@ionic/react';
import {PageWrapper} from '../components/PageWrapper';
import {StarSelector} from '../components/StarSelector';
import {ExpositionSelectorMultiple, ExpositionSelectorSingle} from '../components/ExpositionSelector';
import {fileWriterFactory} from '../data/files/writer';
import {FileWriterType} from '../data/files/FileWriterFactory';
import {TemporaryImage} from '../data/images';
import {TemporaryVideo} from '../data/videos';
import {ImagePicker} from '../components/ImagePicker';
import {VideoPicker} from '../components/VideoPicker';
import {Exposition} from "../data/ConditionsReport";

export const ElementOverview = () => {
    const [ stars, setStars ] = React.useState(0);
    const [ exposition, setExposition ] = React.useState<Exposition|null>(null);
    const [ expositionMulti, setExpositionMulti ] = React.useState<Exposition[]>([]);
    const [ images, setImages ] = React.useState<TemporaryImage[]>([]);
    const [ videos, setVideos ] = React.useState<TemporaryVideo[]>([]);
    const writer = fileWriterFactory.createFileWriter(FileWriterType.MiniConditionsReport, 'new');

    const reset = () => {
        setStars(0);
        setExposition(null);
        setExpositionMulti([]);
        setImages([]);
        setVideos([]);
    }

    return <PageWrapper title="Element Overview" showBackButton={true}>
        <IonList>
            <IonItem>
                <IonButton id="reset_element_overview" onClick={reset}>Reset</IonButton>
            </IonItem>
            <IonItem>
                <IonLabel>StarSelector</IonLabel>
                <StarSelector id="star_selector" value={stars} max={5} onChange={setStars} />
                <div id="star_selector_output">{stars}</div>
            </IonItem>
            <IonItem>
                <IonLabel>
                    ExpositionSelectorSingle
                    <div id="exposition_selector_single_output">{exposition}</div>
                </IonLabel>
                <ExpositionSelectorSingle exposition={exposition} onChange={setExposition} id="exposition_selector_single" />
            </IonItem>
            <IonItem>
                <IonLabel>
                    ExpositionSelectorMultiple
                    <div id="exposition_selector_multi_output">{expositionMulti.join(',')}</div>
                </IonLabel>
                <ExpositionSelectorMultiple expositions={expositionMulti} onChange={setExpositionMulti} id="exposition_selector_multi" />
            </IonItem>
            <ImagePicker writer={writer} maxWidthPx={2000} maxHeightPx={2000} maxSizeMb={3} images={images} onChange={setImages} />
            <VideoPicker writer={writer} max={3} maxSizeMb={200} maxLengthS={30} videos={videos} onChange={setVideos} />
        </IonList>
    </PageWrapper>
}