import {
  ConditionsReportEditingSteps,
  StepData, stepNames, StepValidationResult,
  useInProgressProConditionsReport
} from "../../../data/ConditionsReportProEditing";
import React, {useEffect} from "react";
import {useI18n} from "../../../i18n/i18n";
import {IonButton, IonItem, IonLabel, IonList} from "@ionic/react";
import {Alert} from "@mui/material";
import {ConditionsReportWithTemporaryMedia, convertStepDataToProCr} from "./conversion";
import {ProConditionsReport} from "../../../data/ConditionsReport";
import {SubmitGeneric} from "../SubmitGeneric";
import {useParams} from "react-router";

export const SubmitPro: React.FunctionComponent = () => {
  const { id } = useParams() as { id: string };
  const { locale } = useI18n();
  const {steps, stepData, goToStep, deleteInProgressConditionsReport, validation } = useInProgressProConditionsReport();

  const [ cr, setCr ] = React.useState<ConditionsReportWithTemporaryMedia<ProConditionsReport> | null>(null);

  useEffect(() => {
    setCr(convertStepDataToProCr(stepData[id], locale));
  }, [stepData]);

  const goToLastStep = () => {
    goToStep(id, stepNames[stepNames.length - 1]);
  };

  return <SubmitGeneric cr={cr} goBack={goToLastStep} deleteInProgressConditionsReport={() => deleteInProgressConditionsReport(id)}>
    <ValidationDetails goToStep={<TStep extends keyof StepData>(step: TStep) => goToStep(id, step)} steps={steps} validation={validation[id]}/>
  </SubmitGeneric>
}

interface ValidationDetailsProps {
  goToStep: (step: keyof ConditionsReportEditingSteps) => void,
  steps: ConditionsReportEditingSteps,
  validation: { [K in keyof StepData]: StepValidationResult<StepData[K]> },
}

const ValidationDetails: React.FunctionComponent<ValidationDetailsProps> = ({goToStep, steps, validation}) => {
  const {label} = useI18n();

  const errors = [];

  for (const stepName of stepNames) {
    const step = steps[stepName];
    if (validation[stepName] && Object.keys(validation[stepName]).length > 0) {
      const messages = [];
      for (const m of Object.values(validation[stepName])) {
        messages.push(...m);
      }
      errors.push(<IonItem key={stepName}>
        <IonLabel>
          <IonButton onClick={() => goToStep(stepName)}>
            {label(step.titleLabel)}
          </IonButton>
        </IonLabel>
        <IonList>
          {messages.map((message, index) => <IonItem key={index}>{message}</IonItem>)}
        </IonList>
      </IonItem>);
    }
  }

  if (errors.length === 0) {
    return null;
  }

  return <Alert severity="warning">
    <h2>{label('submit.error.validation.title')}</h2>
    <IonList>{errors}</IonList>
  </Alert>;
};