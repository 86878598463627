import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonTitle,
    IonToolbar,
    useIonLoading,
} from '@ionic/react';
import React from 'react';
import {Map, MapCameraChangedEvent, Marker, useMap} from '@vis.gl/react-google-maps';
import {useLoginState} from '../../data/Login';
import {ShortSpot, SpotCreation, spotRepository} from '../../data/repository/Spot';
import {Alert} from '@mui/material';
import {useOnlineState} from '../../data/Helpers';
import {LabelFunc, useI18n} from '../../i18n/i18n';
import {IonItemWithValidation, ValidationWarnings} from "../ValidationWarning";
import {GeolocationInformation, geolocationProvider} from "../../data/geolocation";
import {formatDistance} from "../../data/geolocation/helpers";

interface SpotWithDistance extends ShortSpot {
    distance: number | null
}

function addDistanceToSpot(spot: ShortSpot, currentLocation: GeolocationInformation): SpotWithDistance {
    const distance = geolocationProvider.distanceBetween({ latitude: spot.location.lat, longitude: spot.location.long }, currentLocation);
    return { ...spot, distance };
}

const INITIAL_LOCATION = {
    lat: 46.8182,
    lng: 8.2275,
};


type SpotCreationValidationErrors = Partial<Record<keyof SpotCreation, string[]>>;

function validate(spot: SpotCreation, label: LabelFunc): SpotCreationValidationErrors  {
    const errors: SpotCreationValidationErrors = {};

    if (spot.title === '') {
        errors.title = [ label('spot.title.validation.exists') ];
    }
    if (spot.location.latitude === 0 || spot.location.longitude === 0) {
        errors.location = [ label('spot.location.validation.exists') ];
    }
    if (spot.location.latitude === INITIAL_LOCATION.lat && spot.location.longitude === INITIAL_LOCATION.lng) {
        errors.location = [ label('spot.location.validation.default') ];
    }

    return errors;
}

interface LocationCreateModalProps {
    onDismiss: () => void,
    onCreate: (location: ShortSpot) => void,
}


export const LocationCreateModal: React.FunctionComponent<LocationCreateModalProps> = (props) => {
    const [isOnline] = useOnlineState();
    const map = useMap();
    const {user} = useLoginState();
    const {label} = useI18n();
    const [ allSpots, setAllSpots ] = React.useState<SpotWithDistance[]>([ ]);
    const [ suggestions, setSuggestions ] = React.useState<SpotWithDistance[]>([ ]);

    const [ validation, setValidation ] = React.useState<SpotCreationValidationErrors>({});
    const [ showValidation, setShowValidation ] = React.useState<boolean>(false);
    const [ spot, setSpot ] = React.useState<SpotCreation>({
        title: '',
        location: {
            latitude: INITIAL_LOCATION.lat,
            longitude: INITIAL_LOCATION.lng,
        }
    });

    const [error, setError] = React.useState<string | null>(null);
    const [showLoadingModal, dismissLoadingModal] = useIonLoading();

    function updateSpots(spots: ShortSpot[] | null, newSpotLocation: GeolocationInformation) {
        spots = spots ?? allSpots;

        const newSpots = spots
          .map(spot => addDistanceToSpot(spot, newSpotLocation))
          .sort((a, b) => {
              if (a.distance && b.distance) {
                  return a.distance - b.distance;
              }

              return 0
          })

        setAllSpots(newSpots);
    }

    React.useEffect(() => setValidation(validate(spot, label)), [ spot ]);

    React.useEffect(() => {
        spotRepository.list(spots => updateSpots(spots, spot.location));
    }, [ ])
    React.useEffect(() => updateSpots(null, spot.location), [ spot.location ]);
    React.useEffect(() => {
        const newSuggestions = [ ];

        for (const spot of allSpots) {
            if (newSuggestions.length >= 2) {
                break;
            }

            if (spot.distance === null) {
                continue;
            }

            if (spot.distance > 1000) {
                continue;
            }

            newSuggestions.push(spot);
        }

        setSuggestions(newSuggestions);
    }, [ allSpots ])


    function updateCoordinates(latitude: number, longitude: number) {
        setSpot({
            ...spot,
            location: {
                latitude,
                longitude,
            }
        });
    }


    const create = async () => {
        setError(null);
        setShowValidation(true);
        if (Object.values(validation).length > 0) {
            return;
        }

        showLoadingModal(label('location_create.loading'));

        spotRepository.create(user!.token, spot).then(spot => {
            props.onCreate(spot);
            dismissLoadingModal();
        }).catch(e => {
            setError(`${e}`);
            dismissLoadingModal();
        });
    };

    const selectSuggestion = (suggestion: SpotWithDistance) => {
        props.onCreate(suggestion);
    }


    React.useEffect(() => {
        if (!map) {
            return;
        }

        geolocationProvider.getGeolocation().then(location => {
            updateCoordinates(location.latitude, location.longitude);
            map.setCenter({lat: location.latitude, lng: location.longitude});
            map.setZoom(12);
        }).catch(err => console.error(err));
    }, [map]);

    const onCenterChanged = (e: MapCameraChangedEvent) => {
        updateCoordinates(e.detail.center.lat, e.detail.center.lng);
    };

    return <React.Fragment>
        <IonHeader>
            <IonToolbar>
                <IonTitle>
                    {label('location_create.title')}
                </IonTitle>
                <IonButtons slot="end">
                    <IonButton color="danger" onClick={() => props.onDismiss()}>
                        {label('location_create.action.cancel')}
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>

                <div style={{ flexShrink: 0 }}>
                    <IonItemWithValidation errors={showValidation ? validation.title : undefined}>
                        <IonInput
                            type="text"
                            label={label('spot.title') + '*'}
                            value={spot.title}
                            onIonInput={e => setSpot({ ...spot, title: e.detail.value! })}
                            placeholder={label('spot.title.placeholder')}
                        />
                    </IonItemWithValidation>
                </div>

                <Map
                    disableDefaultUI={true}
                    onCenterChanged={onCenterChanged}
                    defaultZoom={8}
                    defaultCenter={INITIAL_LOCATION}
                >
                    <Marker position={{lat: spot.location.latitude, lng: spot.location.longitude}}/>
                </Map>

                {suggestions.length > 0 && <div className="ion-padding">
                    <h2>{label('location_create.suggestions')}</h2>

                    <div style={{ display: 'flex' }}>
                        {suggestions.map(spot => <IonButton key={spot.uuid} onClick={() => selectSuggestion(spot)} fill="outline">
                            {spot.title} {spot.distance && `(${formatDistance(spot.distance)})`}
                        </IonButton>)}
                    </div>
                </div>}

                {!isOnline && <Alert severity="warning">
                    <h2>{label('location_create.error.no_internet.title')}</h2>
                    <p>{label('location_create.error.no_internet.message')}</p>
                </Alert>}

                {error === null ? null : <Alert severity="error">{label('location_create.error.generic')}</Alert>}
                {showValidation && <ValidationWarnings errors={validation}/>}

                <IonButton color={Object.keys(validation).length > 0 ? 'medium' : 'primary'} onClick={create}>
                    {label('location_create.action.create')}
                </IonButton>
            </div>
        </IonContent>
    </React.Fragment>;
};