import React, {useEffect} from 'react';
import {ConditionsReportEditingSteps, ID_NEW, useInProgressProConditionsReport} from '../../../data/ConditionsReportProEditing';
import {IonButton, IonGrid, IonIcon, IonLoading, IonToolbar} from '@ionic/react';
import {PageWrapper} from '../../../components/PageWrapper';
import {ValidationWarnings} from '../../../components/ValidationWarning';
import {useHistory, useParams} from 'react-router';
import {conditionsReportRepository} from '../../../data/repository/ConditionsReport';
import {useI18n} from '../../../i18n/i18n';
import {useLoginState} from '../../../data/Login';
import {Redirect} from 'react-router-dom';
import {fileWriterFactory} from "../../../data/files/writer";
import {FileWriterType} from "../../../data/files/FileWriterFactory";
import {ErrorBox} from "../../../components/ErrorBox";

interface StepFormWrapperProps<TStep extends keyof ConditionsReportEditingSteps> {
    stepName: TStep,
    step: ConditionsReportEditingSteps[TStep],
}


export function StepFormWrapper<TStep extends keyof ConditionsReportEditingSteps>(props: StepFormWrapperProps<TStep>) {
    const {user} = useLoginState();
    const { id } = useParams() as { id: string };
    const history = useHistory();
    const {label} = useI18n();
    const fileWriter = fileWriterFactory.createFileWriter(FileWriterType.ProConditionsReport, id);
    const [ error, setError ] = React.useState<string | null>(null);

    const {
        initialize,
        updateStepData,
        stepData,
        steps,
        validation,
        showValidation,
        goToStep,
        goToPreview,
    } = useInProgressProConditionsReport();

    useEffect(() => {
        if (id === ID_NEW) {
            initialize(null);
            return;
        }

        conditionsReportRepository.load(id)
          .then(cr => {
              if (cr === null) {
                  setError(label('editing.error.not_found'));
              } else {
                  initialize(cr);
              }
          });
    }, [id]);

    const stepNames = Object.keys(steps) as (keyof ConditionsReportEditingSteps)[];
    const currentIndex = stepNames.indexOf(props.stepName);
    const nextStep = stepNames[currentIndex + 1] ?? null;
    const previousStep = stepNames[currentIndex - 1] ?? null;

    const goBack = () => {
        if (previousStep) {
            goToStep(id, previousStep);
        } else {
            history.push('/');
        }
    };

    const iconColor = (step: keyof ConditionsReportEditingSteps) => {
        if (step === props.stepName) {
            return 'light';
        }

        if (showValidation[id] && Object.keys(validation[id][step] ?? {}).length > 0) {
            return 'warning';
        }

        return 'medium';
    };

    if (user === null) {
        return <Redirect to="/home"/>;
    }

    const content: React.ReactNode[] = [ ];
    if (error) {
        content.push(<ErrorBox key={`error-${id}-${props.stepName}`} error={error} />);
    } else {
        if (showValidation[id]) {
            content.push(
                <ValidationWarnings
                    id="validation_messages"
                    key={`validation-${id}-${props.stepName}`}
                    errors={validation[id][props.stepName] ?? {}}
                />
            )
        }
        if (stepData[id]) {
            content.push(<props.step.render
              key={`form-${id}-${props.stepName}`}
              data={stepData[id][props.stepName] as any}
              step={props.step}
              allData={stepData[id]}
              validation={(showValidation[id] ? validation[id][props.stepName] ?? {} : {}) as any}
              showValidation={showValidation[id]}
              updateData={(data: any) => updateStepData(id, props.stepName, data)}
              fileWriter={fileWriter}
            />)
        } else {
            content.push(<IonLoading key={`loading-${id}-${props.stepName}`} />)
        }
    }

    return <PageWrapper
      title={label(props.step.titleLabel)}
      showBackButton={true}
      onBack={goBack}
      additionalHeaderContent={
          <IonToolbar color="primary">
              <IonGrid style={{display: 'flex', justifyContent: 'space-around'}}>
                  {stepNames.map(step => <IonButton id={`go-to-step-${step}`} fill="clear" style={{flexGrow: 1}} key={step}
                                                    onClick={() => goToStep(id, step)}>
                        <IonIcon color={iconColor(step)} icon={steps[step].icon} size="large"/>
                    </IonButton>)}
                </IonGrid>
            </IonToolbar>
        }
        footer={
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    {previousStep &&
                        <IonButton id="go-to-prev" onClick={() => goToStep(id, previousStep)}>{label('editing.action.back')}</IonButton>}
                </div>
                {nextStep ? <IonButton id="go-to-next" onClick={() => goToStep(id, nextStep)}>{label('editing.action.next')}</IonButton> :
                    <IonButton id="go-to-preview" onClick={() => goToPreview(id)}>{label('editing.action.send')}</IonButton>}
            </div>
        }
    >
        {content}
        <p className="ion-padding">{label('editing.required_fields')}</p>
    </PageWrapper>;
}