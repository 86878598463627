import {ConditionsReport} from '../ConditionsReport';
import {apiClient, ApiError} from '../api';

export type ConditionsReportWithId = ConditionsReport & {uuid: string, url?: string};

class ConditionsReportRepository {
    async submit(cr: ConditionsReport, token: string): Promise<ConditionsReportWithId> {
        const response = await apiClient.request({
            endpoint: 'api/v2/conditions_report',
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: cr,
        });

        return await response.json() as ConditionsReportWithId;
    }

    async load(id: string): Promise<ConditionsReportWithId|null> {
        try {
            const response = await apiClient.request({
                endpoint: `api/v2/conditions_report/${id}`,
                method: 'GET',
            });

            return await response.json() as ConditionsReportWithId;
        } catch (e: any) {
            if (e instanceof ApiError && e.status === 404) {
                return null;
            }

            throw e;
        }
    }
}

export const conditionsReportRepository = new ConditionsReportRepository();