import {PickedFile} from '@capawesome/capacitor-file-picker';
import {GenericVideoHandlingBackend} from './GenericVideoHandlingBackend';
import {createVideoThumbnailUsingCapacitorPlugin} from "./helpers";
import {fileHandling, FileOnDisk, FileWriter} from "../files";
import {MediaFile} from '@awesome-cordova-plugins/media-capture';

export class IosVideoHandlingBackend extends GenericVideoHandlingBackend {
    async canLoadVideosFromCamera(): Promise<boolean> {
        return true;
    }

    async canLoadVideosFromGallery(): Promise<boolean> {
        return true;
    }

    protected getVideoLength(file: PickedFile): Promise<number> {
        return Promise.resolve(file.duration!);
    }

    // Ios: The file path returned by pickVideos can be accessed directly.
    protected async toFileOnDisk(writer: FileWriter, file: PickedFile|MediaFile): Promise<FileOnDisk> {
        const path = 'fullPath' in file ? file.fullPath! : file.path!;

        return fileHandling.getFile(path);
    }

    protected async createVideoThumbnail(writer: FileWriter, path: string): Promise<FileOnDisk> {
        const thumbnail = await createVideoThumbnailUsingCapacitorPlugin(path);

        return this.fileHandling.getFile(thumbnail)
    }
}