import {ConditionsReportWithId} from "./repository/ConditionsReport";
import {ConditionsReportMiniFormData, emptyFormData, validate} from "../pages/CreationForm/Mini/Form";
import React from "react";
import {useHistory} from "react-router";
import {ID_NEW} from "./ConditionsReportProEditing";
import {conditionsReportEditingStorage} from "./storage";
import {ConditionsReportMiniEditingPage} from "../pages/CreationForm/Mini/Page";
import {Route} from "react-router-dom";
import {useI18n} from "../i18n/i18n";
import {SubmitMini} from "../pages/CreationForm/Mini/SubmitMini";
import {fileWriterFactory} from "./files/writer";
import {FileWriterType} from "./files/FileWriterFactory";
import {fileHandling} from "./files";

export type ConditionsReportMiniValidationData = Partial<Record<keyof ConditionsReportMiniFormData, string[]>>

interface ConditionsReportMiniEditingContext {
  initialize: (cr: ConditionsReportWithId | null) => void,
  formData: Record<string, ConditionsReportMiniFormData>,
  updateFormData: (id: string, data: Partial<ConditionsReportMiniFormData>) => Promise<void>,
  validation: Record<string, ConditionsReportMiniValidationData>,
  showValidation: Record<string, boolean>,
  deleteInProgressConditionsReport: (id: string) => Promise<void>,
  goToPreview: (id: string) => void,
}

export const ConditionsReportMiniEditingContext = React.createContext<ConditionsReportMiniEditingContext | null>(null);
export const ConditionsReportMiniEditingProvider: React.FunctionComponent<{ children: React.ReactNode}> = ({ children }) => {
  const history = useHistory();
  const { label } = useI18n();

  const [ formData, setFormData ] = React.useState<Record<string, ConditionsReportMiniFormData>>({ });
  const [ validation, setValidation ] = React.useState<Record<string, ConditionsReportMiniValidationData>>({});
  const [ showValidation, setShowValidation ] = React.useState<Record<string, boolean>>({ });

  // Load the current active form data from storage and merge with default data
  React.useEffect(() => {
    conditionsReportEditingStorage.list().then(list => {
      const newFormData = { ...formData };

      for (const listItem of list) {
        if (listItem.type !== 'mini') {
          continue;
        }

        newFormData[listItem.id] = { ...emptyFormData(), ...listItem.data };
      }

      setFormData(newFormData);
    })
  }, [ ]);

  // Update validation
  React.useEffect(() => {
    const newValidation = { ...validation };
    for (const id of Object.keys(formData)) {
      newValidation[id] = validate(formData[id], label);
    }
    setValidation(newValidation);
  }, [ formData ]);

  async function initialize (cr: ConditionsReportWithId | null) {
    if (cr === null) {
      formData[ID_NEW] = formData[ID_NEW] ?? emptyFormData();
      setFormData({ ...formData });

      validation[ID_NEW] = validate(formData[ID_NEW], label);
      setValidation({ ...validation });

      showValidation[ID_NEW] = false;
      setShowValidation({ ...showValidation });
      return;
    }

    throw new Error('Editing existing conditions reports is not yet implemented');
  }

  async function updateFormData (id: string, data: Partial<ConditionsReportMiniFormData>): Promise<void> {
    const newFormData = { ...formData };
    newFormData[id] = { ...newFormData[id], ...data };

    setFormData(newFormData);
    await conditionsReportEditingStorage.storeMiniFormData(id, newFormData[id]);
  }

  async function deleteInProgressConditionsReport (id: string): Promise<void> {
    if (!formData[id]) {
      return
    }

    for (const video of formData[id].videos) {
      try {
        await fileHandling.deleteFile(video.thumbnail);
      } catch (e) {
        console.error('Could not delete thumbnail of video', video, e);
      }
      try {
        await fileHandling.deleteFile(video);
      } catch (e) {
        console.error('Could not delete video', video, e);
      }
    }
    for (const photo of formData[id].images) {
      try {
        await fileHandling.deleteFile(photo);
      } catch (e) {
        console.error('Could not delete photo', photo, e);
      }
    }

    await conditionsReportEditingStorage.removeMiniFormData(id);
    await fileWriterFactory.createFileWriter(FileWriterType.MiniConditionsReport, id).clearStorage();

    const newFormData = { ...formData };
    delete newFormData[id];
    setFormData(newFormData);
  }

  async function goToPreview(id: string): Promise<void> {
    const newShowValidation = { ...showValidation };
    newShowValidation[id] = true;
    setShowValidation(newShowValidation);

    if (Object.keys(validation[id] ?? { }).length > 0) {
      return;
    }

    history.push(`/edit/mini/${id}/submit`);
  }

  return <ConditionsReportMiniEditingContext.Provider value={{
    initialize,
    formData,
    updateFormData,
    validation,
    showValidation,
    deleteInProgressConditionsReport,
    goToPreview
  }}>{children}</ConditionsReportMiniEditingContext.Provider>
}

export function useInProgressMiniConditionsReport(): ConditionsReportMiniEditingContext {
  return React.useContext(ConditionsReportMiniEditingContext)!;
}

export function getConditionsReportMiniRoutes(): React.ReactElement[] {
  return [
    <Route exact path={`/edit/mini/:id`} key="mini"><ConditionsReportMiniEditingPage /></Route>,
    <Route exact path={`/edit/mini/:id/submit`} key="mini-submit"><SubmitMini /></Route>,
  ];
}
