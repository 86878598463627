import React from 'react';
import './Home.css';
import {PageWrapper} from '../components/PageWrapper';
import {
    IonButton,
    IonButtons,
    IonIcon,
    IonImg,
    IonItem,
    IonList,
    IonListHeader,
    IonSelect,
    IonSelectOption,
    IonTitle, IonToolbar,
    useIonLoading
} from '@ionic/react';
import logo from '../assets/logo.svg';
import {useLoginState} from '../data/Login';
import {addOutline, pencilOutline, logInOutline, logOutOutline, removeOutline} from 'ionicons/icons';
import {environment} from '../environment';
import {PwdInput} from '../components/PwdInput';
import {Alert} from '@mui/material';
import {useOnlineState} from '../data/Helpers';
import {useNews} from '../data/News';
import {MultipleNews} from '../components/News';
import {useI18n} from '../i18n/i18n';
import {User} from "../data/repository/User";
import {ID_NEW, useInProgressProConditionsReport} from "../data/ConditionsReportProEditing";
import {useInProgressMiniConditionsReport} from "../data/ConditionsReportMiniEditing";

const Home: React.FC = () => {
    const news = useNews();
    const {user, logout, isFixed} = useLoginState();
    const {label, locales, locale, setLocale} = useI18n();

    const [tapsOnBuild, setTapsOnBuild] = React.useState<number>(0);
    const [showDebug, setShowDebug] = React.useState<boolean>(false);

    const tapOnBuild = () => {
        const taps = tapsOnBuild + 1;
        setTapsOnBuild(taps);
        if (taps >= 5) {
            setShowDebug(true);
        }
    }

    // @ts-expect-error Helper for testing
    window.__enable_debug = () => setShowDebug(true);

    const languageLabel = locales.map(lang => label('language', {}, lang)).join(' / ');

    if (user === null && isFixed) {
        return <PageWrapper title="PowderGuide">
            <Alert severity="info">
                <h3>{label('home.fixed_login.title')}</h3>
                <p>{label('home.fixed_login.message')}</p>
            </Alert>
        </PageWrapper>;
    }

    return <PageWrapper title="PowderGuide">
        <a href={environment.powderguideWebsiteUrl} target="_blank" rel="noreferrer">
            <IonImg src={logo}/>
        </a>
        <MultipleNews news={news}/>
        <div className="ion-padding-horizontal">
            <h3>{label('home.title', {user: user?.username ?? ''})}</h3>

            <p
                className="ion-padding-bottom"
                dangerouslySetInnerHTML={{__html: label('home.text', {
                    mail: 'app@powderguide.com',
                    proMail: 'conditionsreporter@powderguide.com',
                    iosAppUrl: 'https://apps.apple.com/in/app/conditionsreport-app/id1542996357',
                    webAppUrl: 'https://app.powderguide.com',
                    androidAppUrl: 'https://play.google.com/store/apps/details?id=powderguide.cr.app',
                })}}
            />
            <p>
                {label('home.signoff')}
            </p>
        </div>

        <div className="ion-padding-horizontal">
            <IonSelect label={languageLabel} value={locale} onIonChange={e => setLocale(e.detail.value)}>
                {locales.map(locale => <IonSelectOption key={locale} value={locale}>{label('current_language_name', {}, locale)}</IonSelectOption>)}
            </IonSelect>
        </div>

        {user === null ? <LoginForm/> : <CurrentUserMenu user={user} isFixed={isFixed} logout={logout}/>}

        <div id="version-number" className="ion-padding" style={{marginTop: 75, color: '#ccc', fontSize: '.8em'}}
             onClick={() => tapOnBuild()}>
            Version {environment.version}
        </div>

        {showDebug && <div className="ion-padding-top">
            <IonButton id="go-to-debug" routerLink="/debug">Debug</IonButton>
            <IonButton id="go-to-element-overview" routerLink="/element-overview">Element Overview</IonButton>
        </div>}
    </PageWrapper>;
};

interface CurrentUserMenuProps {
    isFixed: boolean,
    user: User,
    logout: () => any
}


const CurrentUserMenu: React.FunctionComponent<CurrentUserMenuProps> = (props) => {
    const {label} = useI18n();
    const { stepData: proStepData, deleteInProgressConditionsReport: deleteInProgressProConditionsReport } = useInProgressProConditionsReport();
    const { formData: miniFormData, deleteInProgressConditionsReport: deleteInProgressMiniConditionsReport } = useInProgressMiniConditionsReport();

    const clearPro = async (id: string) => {
        await deleteInProgressProConditionsReport(id);
    };

    const clearMini = async (id: string) => {
        await deleteInProgressMiniConditionsReport(id);
    }

    const defaultString = (str: string|undefined, defaultString: string): string => {
        if (str === undefined) {
            return defaultString;
        }

        return str.trim().length === 0 ? defaultString : str;
    }

    let crPro: React.ReactNode | null = null;
    if (props.user.is_cr_pro) {
        crPro = <IonList id="pro-list">
            <IonListHeader>
                <IonTitle>{label('cr.pro')}</IonTitle>
            </IonListHeader>
            {Object.entries(proStepData).map(([ id, stepData ]) => <IonItem key={id}>
                <IonToolbar>
                    {defaultString(stepData.step4?.title, label('home.cr.new'))}
                    {stepData.step1?.date && <i>&nbsp;&nbsp;({stepData.step1.date})</i>}

                    <IonButtons slot="end">
                        <IonButton fill="solid" color="primary" routerLink={`/edit/pro/${id}`} id={`edit-pro-${id}`}>
                            <IonIcon icon={pencilOutline} style={{marginRight: '.5em'}}/>
                            {label('home.cr.edit')}
                        </IonButton>
                        <IonButton color="danger" onClick={() => clearPro(id)} id={`delete-pro-${id}`}>
                            <IonIcon icon={removeOutline} style={{marginRight: '.5em'}}/>
                            {label('home.cr.discard')}
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonItem>)}
            {!proStepData[ID_NEW] && <IonItem>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton fill="solid" color="primary" routerLink="/edit/pro/new" id="create-pro">
                            <IonIcon icon={addOutline} style={{marginRight: '.5em'}}/>
                            {label('home.cr.new')}
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonItem>}
        </IonList>;
    }

    return <React.Fragment>
        {crPro}
        {crPro && <hr />}
        <IonList id="mini-list">
            <IonListHeader>
                <IonTitle>{label('cr.mini')}</IonTitle>
            </IonListHeader>
            {Object.entries(miniFormData).map(([ id, formData ]) => <IonItem key={id}>
                <IonToolbar>
                    {defaultString(formData?.title, label('home.mr.new'))}
                    {formData.date && <i>&nbsp;&nbsp;({formData.date})</i>}

                    <IonButtons slot="end">
                        <IonButton fill="solid" color="primary" routerLink={`/edit/mini/${id}`} id={`edit-mini-${id}`}>
                            <IonIcon icon={pencilOutline} style={{marginRight: '.5em'}}/>
                            {label('home.mr.edit')}
                        </IonButton>
                        <IonButton color="danger" onClick={() => clearMini(id)} id={`delete-mini-${id}`}>
                            <IonIcon icon={removeOutline} style={{marginRight: '.5em'}}/>
                            {label('home.mr.discard')}
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonItem>)}
            {!miniFormData[ID_NEW] && <IonItem>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton fill="solid" color="primary" routerLink="/edit/mini/new" id="create-mini">
                            <IonIcon icon={addOutline} style={{marginRight: '.5em'}}/>
                            {label('home.mr.new')}
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonItem>}
        </IonList>
        <br/>
        <br/>
        <br/>
        {!props.isFixed && <IonButton id="logout" color="danger" expand="full" onClick={props.logout}>
            <IonIcon icon={logOutOutline} style={{marginRight: '.5em'}}/>
            {label('home.action.logout')}
        </IonButton>}
    </React.Fragment>;
};


const LoginForm: React.FunctionComponent = () => {
    const [present, dismiss] = useIonLoading();
    const {login} = useLoginState();
    const [isOnline] = useOnlineState();
    const {label} = useI18n();

    const [username, setUsername] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [error, setError] = React.useState<string | null>(null);

    const performLogin = async () => {
        present();
        try {
            const userData = await login(username, password);
            if (userData === null) {
                setError(label('login.error.invalid_credentials'));
            }
        } catch (e) {
            setError(`${e}`);
        }
        dismiss();
    };

    return <IonList>
        {error && <div className="ion-padding-bottom">
            <Alert severity="error">{error}</Alert>
        </div>}
        <div className="ion-padding-bottom">
            <PwdInput id="login_username" value={username} onChange={setUsername} type="text" label={label('login.username')}/>
        </div>
        <div className="ion-padding-bottom">
            <PwdInput id="login_password" value={password} onChange={setPassword} type="password" label={label('login.password')}/>
        </div>
        {!isOnline && <Alert severity="warning">
            <h2>{label('login.no_internet.title')}</h2>
            <p>{label('login.no_internet.message')}</p>
        </Alert>}
        <IonButton id="login_submit" disabled={!isOnline} expand="full" onClick={performLogin}>
            <IonIcon icon={logInOutline} style={{marginRight: '0.5rem'}}/>
            {label('login.action.login')}
        </IonButton>
        <IonList class="ion-padding-top">
            <IonButton color="secondary" href={`${environment.powderguideWebsiteUrl}/password-reset`}>
                {label('login.action.password_forgotten')}
            </IonButton>
            <IonButton color="secondary" href={`${environment.powderguideWebsiteUrl}/registration`}>
                {label('login.action.register')}
            </IonButton>
        </IonList>
    </IonList>;
};

export default Home;
