import {Step1Data, Step1EmptyData, Step1Form, validateStep1} from '../pages/CreationForm/Pro/Step1';
import {Step2Data, Step2EmptyData, Step2Form, validateStep2} from '../pages/CreationForm/Pro/Step2';
import {Step3Data, Step3EmptyData, Step3Form, validateStep3} from '../pages/CreationForm/Pro/Step3';
import {Step4Data, Step4EmptyData, Step4Form, validateStep4} from '../pages/CreationForm/Pro/Step4';
import React, {useEffect} from 'react';
import {conditionsReportEditingStorage} from './storage';
import {checkmarkDoneOutline, locationOutline, snowOutline, warningOutline} from 'ionicons/icons';
import {useHistory, useParams} from 'react-router';
import {StepFormWrapper} from '../pages/CreationForm/Pro/StepFormWrapper';
import {Redirect, Route} from 'react-router-dom';
import {ConditionsReportWithId} from './repository/ConditionsReport';
import {LabelFunc, LanguageLabel, useI18n} from '../i18n/i18n';
import {SubmitPro} from "../pages/CreationForm/Pro/SubmitPro";
import {fileHandling, FileWriter} from "./files";
import {fileWriterFactory} from "./files/writer";
import {FileWriterType} from "./files/FileWriterFactory";

export const ID_NEW = 'new';

export interface StepData {
    step1: Step1Data,
    step2: Step2Data,
    step3: Step3Data,
    step4: Step4Data,
}

export const stepNames: (keyof StepData)[] = ['step1', 'step2', 'step3', 'step4'];


export const steps: ConditionsReportEditingSteps = {
    step1: {
        titleLabel: 'editing.step_1',
        icon: locationOutline,
        render: Step1Form,
        emptyData: Step1EmptyData,
        validate: validateStep1,
    },
    step2: {
        titleLabel: 'editing.step_2',
        icon: snowOutline,
        render: Step2Form,
        emptyData: Step2EmptyData,
        validate: validateStep2,
    },
    step3: {
        titleLabel: 'editing.step_3',
        icon: warningOutline,
        render: Step3Form,
        emptyData: Step3EmptyData,
        validate: validateStep3,
    },
    step4: {
        titleLabel: 'editing.step_4',
        icon: checkmarkDoneOutline,
        render: Step4Form,
        emptyData: Step4EmptyData,
        validate: validateStep4,
    },
};

export interface ConditionsReportEditingStepDefinition<TData> {
    titleLabel: LanguageLabel,
    icon: string,
    emptyData: () => TData,
    render: React.FunctionComponent<StepScope<TData>>,
    validate: (scope: ValidationScope<TData>) => StepValidationResult<TData>,
}

export type ConditionsReportEditingSteps = {
    [K in keyof StepData]: ConditionsReportEditingStepDefinition<StepData[K]>
}

export type StepValidationResult<T> = Partial<Record<keyof T, string[]>>

export interface StepScope<TData> {
    step: ConditionsReportEditingStepDefinition<TData>,
    data: TData,
    allData: StepData,
    validation: StepValidationResult<TData>,
    showValidation: boolean,
    updateData: (data: Partial<TData>) => Promise<void>,
    fileWriter: FileWriter,
}

export interface ValidationScope<TData> {
    step: ConditionsReportEditingStepDefinition<TData>,
    data: TData,
    allData: StepData,
    label: LabelFunc,
}

function emptyData(steps: ConditionsReportEditingSteps): StepData {
    const data: Partial<StepData> = {};
    for (const key of Object.keys(steps) as (keyof StepData)[]) {
        data[key] = steps[key].emptyData() as any;
    }
    return data as StepData;
}

function emptyValidation(steps: ConditionsReportEditingSteps): { [K in keyof StepData]: StepValidationResult<StepData[K]> } {
    const validation: Partial<{ [K in keyof StepData]: StepValidationResult<StepData[K]> }> = {};
    for (const key of Object.keys(steps) as (keyof StepData)[]) {
        validation[key] = {};
    }
    return validation as { [K in keyof StepData]: StepValidationResult<StepData[K]> };
}


interface ConditionsReportProEditingContext {
    initialize: (cr: ConditionsReportWithId | null) => void,
    steps: ConditionsReportEditingSteps,
    stepData: Record<string, StepData>,
    validation: Record<string, { [K in keyof StepData]: StepValidationResult<StepData[K]> }>,
    showValidation: Record<string, boolean>,
    updateStepData: <TStep extends keyof StepData>(id: string, step: TStep, data: Partial<StepData[TStep]>) => Promise<void>,
    deleteInProgressConditionsReport: (id: string) => Promise<void>,
    goToStep: <TStep extends keyof StepData>(id: string, step: TStep) => void,
    goToPreview: (id: string) => void,
    goToFirstStepWithValidationErrors: (id: string) => boolean,
}

export const ConditionsReportProEditingContext = React.createContext<ConditionsReportProEditingContext | null>(null);
export const ConditionsReportProEditingProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({children}) => {
    const history = useHistory();
    const [stepData, setStepData] = React.useState<Record<string, StepData>>({ });
    const [validation, setValidation] = React.useState<Record<string, { [K in keyof StepData]: StepValidationResult<StepData[K]> }>>({ });
    const [showValidation, setShowValidation] = React.useState<Record<string, boolean>>({ });
    const {label} = useI18n();

    // Load the current active steps from storage and merge them with the default data
    useEffect(() => {
        conditionsReportEditingStorage.list().then(list => {
            const newStepDatas = { ...stepData };

            for (const listItem of list) {
                if (listItem.type !== 'pro') {
                    continue;
                }

                const itemStepData = listItem.data as StepData;
                for (const step of stepNames) {
                    itemStepData[step] = {...steps[step].emptyData(), ...(itemStepData[step] ?? {})} as any;
                }

                newStepDatas[listItem.id] = itemStepData;
            }

            setStepData(newStepDatas);
        })
    }, [ ]);

    // Update validation
    useEffect(() => {
        const newValidation = {...validation};

        for (const id of Object.keys(stepData)) {
            newValidation[id] = emptyValidation(steps);
            for (const step of stepNames) {
                newValidation[id][step] = steps[step].validate({
                    step: steps[step] as any,
                    data: stepData[id][step] as any,
                    allData: stepData[id],
                    label: label,
                });
            }
        }

        setValidation(newValidation);
    }, [stepData]);

    const initialize = (cr: ConditionsReportWithId | null) => {
        if (cr === null) {
            if (stepData[ID_NEW]) {
                return;
            }

            stepData[ID_NEW] = stepData[ID_NEW] ?? emptyData(steps);
            setStepData({...stepData});

            validation[ID_NEW] = validation[ID_NEW] ?? emptyValidation(steps);
            setValidation({...validation});

            showValidation[ID_NEW] = false;
            setShowValidation({...showValidation});
            return;
        }

        throw new Error('Editing existing conditions reports is not yet implemented');
    };

    async function updateStepData<TStep extends keyof StepData>(id: string, step: TStep, data: Partial<StepData[TStep]>): Promise<void> {
        const newStepData = { ...stepData };
        newStepData[id] = {
            ...stepData[id],
            [step]: {
                ...stepData[id][step],
                ...data,
            },
        };

        setStepData(newStepData);
        await conditionsReportEditingStorage.storeProStepData(id, newStepData[id]);
    }



    async function deleteInProgressConditionsReport(id: string): Promise<void> {
        if (!stepData[id]) {
            return;
        }

        for (const video of stepData[id].step4.videos) {
            try {
                await fileHandling.deleteFile(video.thumbnail);
            } catch (e) {
                console.error('Could not delete thumbnail of video', video, e);
            }
            try {
                await fileHandling.deleteFile(video);
            } catch (e) {
                console.error('Could not delete video', video, e);
            }
        }
        for (const photo of stepData[id].step4.images) {
            try {
                await fileHandling.deleteFile(photo);
            } catch (e) {
                console.error('Could not delete photo', photo, e);
            }
        }

        await conditionsReportEditingStorage.removeProStepData(id);
        try {
            await fileWriterFactory.createFileWriter(FileWriterType.ProConditionsReport, id).clearStorage();
        } catch (e) {
            console.error('Could not clear storage for pro conditions report', e);
        }

        const newStepData = { ...stepData };
        delete newStepData[id];
        setStepData(newStepData);
    }

    function goToStep<TStep extends keyof StepData>(id: string, step: TStep): void {
        history.push(`/edit/pro/${id}/${step}`);
    }

    function goToFirstStepWithValidationErrors(id: string): boolean {
        const newShowValidation = { ...showValidation };
        newShowValidation[id] = true;
        setShowValidation(newShowValidation);

        for (const stepName of stepNames) {
            if (Object.keys(validation[id][stepName]).length > 0) {
                goToStep(id, stepName);
                return true;
            }
        }

        return false;
    }

    function goToPreview(id: string): void {
        if (goToFirstStepWithValidationErrors(id)) {
            return;
        }

        history.push(`/edit/pro/${id}/submit`);
    }

    return <ConditionsReportProEditingContext.Provider value={{
        initialize,
        steps,
        stepData,
        validation,
        showValidation,
        updateStepData,
        deleteInProgressConditionsReport,
        goToStep,
        goToPreview,
        goToFirstStepWithValidationErrors,
    }}>
        {children}
    </ConditionsReportProEditingContext.Provider>;
};

export function useInProgressProConditionsReport(): ConditionsReportProEditingContext {
    return React.useContext(ConditionsReportProEditingContext)!;
}

export function getConditionsReportProRoutes(): React.ReactElement[] {
    const routes = stepNames.map((stepName) => (
        <Route exact key={stepName} path={`/edit/pro/:id/${stepName}`}>
            <StepFormWrapper stepName={stepName} step={steps[stepName]}/>
        </Route>
    ));

    routes.push(<Route exact path={`/edit/pro/:id/submit`} key="submit"><SubmitPro/></Route>);
    routes.push(<Route exact path={`/edit/pro/:id`} key="redirect"><RedirectToFirstStep /></Route>);

    return routes;
}

const RedirectToFirstStep: React.FunctionComponent = () => {
    const params = useParams() as { id: string };
    return <Redirect to={`/edit/pro/${params.id}/step1`}/>;
};