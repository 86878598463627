import {Storage} from '@ionic/storage';
import {StepData} from './ConditionsReportProEditing';
import {ConditionsReportMiniFormData} from "../pages/CreationForm/Mini/Form";

const MINI_PREFIX = 'conditions_report_mini_edit.';
const PRO_PREFIX = 'conditions_report_edit.';

export interface ConditionsReportListItem<TType = 'mini'|'pro'> {
    type: TType,
    id: string,
    data: Partial<TType extends 'pro' ? StepData : ConditionsReportMiniFormData>
}

class ConditionsReportEditingStorage {

    private storage: Storage;

    constructor() {
        this.storage = new Storage();
        this.storage.create();
    }

    async loadMiniFormData(id: string): Promise<Partial<ConditionsReportMiniFormData>> {
        const key = MINI_PREFIX + id;
        const result = await this.storage.get(key) as Partial<ConditionsReportMiniFormData> | null;

        return result ?? {};
    }

    async storeMiniFormData(id: string, data: ConditionsReportMiniFormData): Promise<void> {
        const key = MINI_PREFIX + id;
        await this.storage.set(key, data);
    }

    async removeMiniFormData(id: string): Promise<void> {
        const key = MINI_PREFIX + id;
        await this.storage.remove(key);
    }

    async loadProStepData(id: string): Promise<Partial<StepData>> {
        const key = PRO_PREFIX + id;
        const result = await this.storage.get(key) as Partial<StepData> | null;

        return result ?? {};
    }

    async storeProStepData(id: string, data: StepData): Promise<void> {
        const key = PRO_PREFIX + id;
        await this.storage.set(key, data);
    }

    async removeProStepData(id: string): Promise<void> {
        const key = PRO_PREFIX + id;
        await this.storage.remove(key);
    }

    async list(): Promise<ConditionsReportListItem[]> {
        const keys = await this.storage.keys();
        const list: ConditionsReportListItem[] = [ ];

        for (const key of keys) {
            if (key.startsWith(MINI_PREFIX)) {
                const id = key.replace(MINI_PREFIX, '');
                list.push({ type: 'mini', id, data: await this.loadMiniFormData(id) });
            } else if (key.startsWith(PRO_PREFIX)) {
                const id = key.replace(PRO_PREFIX, '');
                list.push({ type: 'pro', id, data: await this.loadProStepData(id) });
            }
        }

        return list;
    }
}

export const conditionsReportEditingStorage = new ConditionsReportEditingStorage();

