import React from 'react';

interface StarSelectorProps {
    value: number,
    onChange?: (value: number) => void,
    max: number,
    id?: string,
}

export const StarSelector: React.FunctionComponent<StarSelectorProps> = (props) => {
    const stars = Array.from({length: props.max}, (_, i) => i + 1);

    const onClick = (star: number) => {
        if (props.onChange) {
            props.onChange(star);
        }
    };

    const widthPerStar = 30;
    const width = widthPerStar * props.max;

    return <svg id={props.id} width={width} height="28" viewBox={`0 0 ${width} 28`} fill="none" xmlns="http://www.w3.org/2000/svg">
        {stars.map(star => <path
          id={props.id ? `${props.id}-star-${star}` : undefined}
          key={star}
          d="M12.9713 2.76924L15.4706 9.17884L15.6968 9.75887L16.3171 9.81192L22.9477 10.3789L17.8482 15.0385L17.4204 15.4294L17.5468 15.9949L19.0838 22.8678L13.5172 19.2411L12.9713 18.8854L12.4254 19.2411L6.85885 22.8678L8.39581 15.9949L8.52229 15.4294L8.09446 15.0385L2.99491 10.3789L9.62557 9.81192L10.2459 9.75887L10.472 9.17884L12.9713 2.76924Z"
          fill={star <= props.value ? `var(--ion-color-primary)` : 'transparent'}
          stroke="var(--ion-color-primary)"
          strokeWidth={2}
          transform={`translate(${(star - 1) * widthPerStar}, 0)`}
          onClick={() => onClick(star)}
          cursor={props.onChange ? 'pointer' : 'default'}
        />)}
    </svg>;
};