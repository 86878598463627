import {IonModal} from '@ionic/react';
import React from 'react';

interface ModalProps {
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
    id?: string;
}

export const Modal: React.FunctionComponent<ModalProps> = (props) => {
    return <IonModal id={props.id} isOpen={props.open} onIonModalDidDismiss={props.onClose}>
        {props.open ? props.children : null}
    </IonModal>;
};